<template>
  <div class="app-container">
    <div class="no-data" v-if="addressList.length === 0">没有数据</div>
    <van-cell-group class="block">
      <van-swipe-cell v-for="(item, index) in addressList" :key="index">
        <van-cell
          icon="location-o"
          center
          is-link
          :title="item.realname + '   ' + item.mobile"
          :label="item.region + item.address"
          @click="selectAddress(item)"
        />
        <template #right>
          <van-button
            @click="edit('edit', item)"
            square
            text="编辑"
            type="primary"
            class="delete-button"
          />
          <van-button
            @click="delAddress(item.address_id)"
            square
            text="删除"
            type="danger"
            class="delete-button"
          />
        </template>
      </van-swipe-cell>
    </van-cell-group>

    <div class="add-btn">
      <van-button type="info" block @click="edit('create', null)"
        >添加地址</van-button
      >
    </div>
    <div class="remark">
      <van-icon class="attention" name="warning-o" /> 向左滑动有惊喜哦！
    </div>

    <van-popup
      v-model="showAddress"
      position="bottom"
      :style="{ height: '100%' }"
    >
      <van-form @submit="onSubmit">
        <van-cell-group class="block">
          <van-field
            v-model="form.realname"
            label="收货人姓名"
            placeholder="请输入"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model.number="form.mobile"
            label="收货人手机"
            placeholder="请输入"
            :rules="[{ required: true }]"
          />
          <van-field
            readonly
            clickable
            is-link
            v-model="form.region"
            placeholder="请选择地区"
            label="选择地区"
            @click="bindShow"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.address"
            label="详细地址"
            placeholder="街道门牌、楼层房间号信息"
            :rules="[{ required: true }]"
          />
        </van-cell-group>
        <van-cell-group class="block">
          <van-cell center title="默认地址">
            <template #right-icon>
              <van-switch
                v-model="form.is_default"
                :active-value="1"
                :inactive-value="2"
                size="24"
              />
            </template>
          </van-cell>
        </van-cell-group>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
      <div style="margin: 16px">
        <van-button round block type="default" @click="showAddress = false"
          >取消</van-button
        >
      </div>
    </van-popup>

    <van-popup v-model="showArea" position="bottom" :style="{ height: '40%' }">
      <van-area
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="bindCancel"
        value="320500"
      />
    </van-popup>
  </div>
</template>
<script>
import AeraInfo from '@/utils/area.js'
import { Notify } from 'vant'
import wx from 'weixin-js-sdk'
export default {
  data () {
    return {
      chosenAddressId: 1,
      addressList: [],

      form: {},
      areaList: AeraInfo,
      searchResult: [],

      type: 'create',
      address: false,
      showAddress: false,
      showArea: false


    }
  },
  mounted () {
    this.getAddress()
  },
  methods: {
    getAddress () {
      this.$axios.post('/wxc/address/lists', {
        count: 999
      }).then(res => {
        this.addressList = res.data.list
      })
    },
    edit (type, data) {
      this.type = type
      if (data) {
        this.form = data
      } else {
        this.form = {}
      }
      this.showAddress = true
    },
    selectAddress (item) {
      let json = item
      json.is_default = 1
      this.$axios.post('/wxc/address/edit', json).then(() => {
        wx.miniProgram.navigateBack({
          delta: 1
        })
        wx.miniProgram.postMessage({ data: item })
        
      })
      // let miniProgramPath = '/pages/' + this.$route.query.page + '/' + this.$route.query.page

    },
    bindShow () {
      this.showArea = true;
    },
    bindCancel () {
      this.showArea = false;
    },
    //地区选择
    onAreaConfirm (val) {
      this.showArea = false;
      this.arrArea = val;
      var addrInfo = val[0].name + val[1].name + val[2].name;
      this.form.region = addrInfo
    },
    delAddress (address_id) {
      this.$axios.post('/wxc/address/remove', {
        address_id: address_id
      }).then(res => {
        this.getAddress()
        Notify({ type: 'success', message: res.msg })
      })
    },
    onSubmit () {
      console.log(this.form)
      this.$axios.post('/wxc/address/' + this.type, this.form).then(res => {
        if (res.code === 200) {
          this.getAddress()
          Notify({ type: 'success', message: res.msg })
          this.showAddress = false
        }

      })
    }
  }
}
</script>
<style lang="scss" scoped>
.delete-button {
  height: 100%;
}
.add-btn {
  padding: 20px;
}
.remark {
  display: flex;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  .attention {
    margin-right: 5px;
  }
}
</style>